import parsePhoneNumberFromString from 'libphonenumber-js';

import { gray } from '@/constants/colors';

export function stringHasContent(string?: string | null) {
  if (typeof string === 'undefined' || string === null) {
    return false;
  }

  const array = /(.|\s)*\S(.|\s)*/.exec(string);
  if (array && array.length >= 0) {
    return true;
  }

  return false;
}

export function hashTruncate(str: string) {
  const stringLength = str.length;
  const truncatedString = [
    str.slice(0, 1),
    '...',
    str.slice(stringLength - 2),
  ].join('');

  return truncatedString;
}

export function getFullName({
  firstName,
  middleName,
  lastName,
}: {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
}) {
  return [firstName, middleName, lastName].filter(Boolean).join(' ') || null;
}

export function adjustToLocalTime(datetimeString: string) {
  const hasTime = datetimeString.includes(':');

  const utcString = hasTime ? datetimeString + 'Z' : datetimeString + 'T00:00Z';

  const date = new Date(utcString);

  if (hasTime) {
    return date
      .toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(', ', ' ');
  } else {
    return date.toLocaleDateString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
}

export function formatDateToLocalISOString(date: Date) {
  const year = date.getFullYear(); // Gets the year (e.g., 2024)
  const month = date.getMonth() + 1; // getMonth() is zero-based, add 1 to get 1-12 for months
  const day = date.getDate(); // Gets the day of the month (1-31)

  // Pad the month and day with leading zeros if necessary
  const formattedMonth = month < 10 ? '0' + month : month;
  const formattedDay = day < 10 ? '0' + day : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

export async function copyToClipboard(value: string, callback?: () => void) {
  await navigator.clipboard.writeText(value);
  callback?.();
}

export function hexToRgba(hex: string, alpha = 1): string {
  const obj = hex.match(/\w\w/g);
  if (obj === null) {
    // just give it a gray
    return hexToRgba(gray['500'], alpha);
  }
  const [r, g, b] = obj.map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

export function isDarkColor(color: string) {
  // just to be safe
  if (color === null) {
    return true;
  }

  const cleanHex = color.replace('#', '');

  if (![3, 6].includes(cleanHex.length)) {
    throw new Error('Invalid hex code');
  }

  const fullHex =
    cleanHex.length === 3
      ? cleanHex
          .split('')
          .map(char => char + char)
          .join('')
      : cleanHex;

  const r = parseInt(fullHex.slice(0, 2), 16);
  const g = parseInt(fullHex.slice(2, 4), 16);
  const b = parseInt(fullHex.slice(4, 6), 16);

  const luminance =
    0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  return luminance <= 0.5;
}

export function formatPhoneNumber(phone: string | null) {
  if (phone === null) {
    return phone;
  }

  const formattedPhone = parsePhoneNumberFromString(phone);

  if (formattedPhone === undefined) {
    return phone;
  }

  return formattedPhone.formatInternational();
}

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
